import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import NotFound from '../views/NotFound.vue'
// import Preview from '../views/Preview.vue'


// Vue.use(VueRouter)



const routes = [
  {
    path: "/home-light",
    name: "HomeLight",
    meta: {
      title: 'WFA - Web From Alex'
    },
    component: () =>
      import("../views/HomeLight.vue"),
  },
  {
    path: "/",
    name: "HomeDark",
    meta: {
      title: 'WFA - Web From Alex'
    },
    component: () =>
      import("../views/HomeDark.vue"),
  },

  {
    path: '/:pathMatch(.*)*',
    name: NotFound,
    meta: {
      title: '404'
    },
    component: ()=> import("../views/NotFound.vue")
  },
  {
    path: "/:lang?",
    name: "Lang",
    meta: {
      title: 'WFA - Web From Alex'
    },
    component: () =>
      import("../views/HomeDark.vue"),
  },
  // {
  //   path: "/",
  //   name: "HomeDark",
  //   meta: {
  //     title: 'WFA - Web From Alex'
  //   },
  //   component: () =>
  //     import("../views/HomeDark.vue"),
  // },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// const router = new VueRouter({
//   mode: 'history',
//   routes,
//   scrollBehavior(savedPosition) {
//     if (savedPosition) {
//       return savedPosition
//     } else {
//       return { x: 0, y: 0 }
//     }
//   },
// })


router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
});


export default router;