<template>
  <div id="app" class="tokyo_tm_all_wrap">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
  export default {
    components: {},
  };
</script>
